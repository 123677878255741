import React, { useState, useEffect, useReducer, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';

import { MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { Form } from "formik";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [status, setStatus] = useState([{id: 'pending', label: 'Pendente'}, {id: 'closed', label: 'Fechado'}, {id: 'open', label: 'Aberto'}])
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { user } = useContext(AuthContext);

  const isAdmin = React.useMemo(_ => user.super || user.profile === 'admin');

  useEffect(() => {
    setSelectedStatus(status.map(item => item.id));
  }, [])


  const fetchTags = () => {

    const controller = new AbortController();

    api.get("/tags/kanban", { signal: controller.signal })
      .then(async response => {
        const fetchedTags = response.data.lista || []; 

        setTags(fetchedTags);

        // Fetch tickets after fetching tags
        await fetchTickets({ queueIds: JSON.stringify(jsonString) }, fetchedTags);

      }).catch(error => {
        console.error(error);
      })

      return controller;
  };

  useEffect(() => {
    const controller = fetchTags();

    return () => {
      controller.abort();
    }
  }, []);

  const fetchUsers = async () => {
    try {
      if(!isAdmin) {
        return;
      }
    
      const response = await api.get("/users");
      setUsers(response.data.users || []);
      setSelectedUsers((response.data.users || []).map(user => user.id));
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchUsers();
  }, [])

  const [file, setFile] = useState({
    lanes: []
  });


  const [tickets, setTickets] = useState([]);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTickets = async (params, tags = []) => {
    try {

      // para cada tag, é realizado um filtro individual para que ao menos 40 tickets sejam exibidos por tag
      const requests = tags.map(tag => api.get("/ticket/kanban", { params: { ...params, tags: JSON.stringify([tag.id]) } }));
      requests.push(api.get("/ticket/kanban", { params: { ...params, hasTags: false } }));
      const response = await Promise.all(requests);

      const tickets = [];

      response.map(({ data }) => data.tickets).flat().forEach(ticket => {
        if (tickets.some(item => item.id === ticket.id)) {
          return;
        }

        tickets.push({...ticket});
      });
      setTickets(tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };


  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: 0,
        sequence: 0,
        title: i18n.t("Em aberto"),
        label: "0",
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
              <div>
                <p>
                  {ticket.contact.number}
                  <br />
                  {ticket.lastMessage}
                </p>
                <button 
                  className={classes.button} 
                  onClick={() => {
                    handleCardClick(ticket.uuid)
                  }}>
                    Ver Ticket
                </button>
              </div>
            ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          sequence: tag.sequence,
          title: tag.name,
          label: tag.id.toString(),
          cards: filteredTickets.map(ticket => ({
            id: ticket.id.toString(),
            label: "Ticket nº " + ticket.id.toString(),
            description: (
              <div>
                <p>
                  {ticket.contact.number}
                  <br />
                  {ticket.lastMessage}
                </p>
                <button 
                  className={classes.button} 
                  onClick={() => {
                    
                    handleCardClick(ticket.uuid)
                  }}>
                    Ver Ticket
                </button>
              </div>
            ),
            title: ticket.contact.name,
            draggable: true,
            href: "/tickets/" + ticket.uuid,          
          })),
          style: { backgroundColor: tag.color, color: "white" }
        };
      }),
    ];

    const sorted = lanes.sort((a, b) => (a.sequence ?? a.id) > (b.sequence ?? b.id) ? 1 : -1);

    setFile({ lanes: sorted });
  };

  const handleCardClick = (uuid) => {  
    //console.log("Clicked on card with UUID:", uuid);
    history.push('/tickets/' + uuid);
  };

  useEffect(() => {
    popularCards();
}, [tags, tickets, reloadData]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
        
          await api.delete(`/ticket-tags/${targetLaneId}`);
        toast.success('Ticket Tag Removido!');
          await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
        toast.success('Ticket Tag Adicionado com Sucesso!');

    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeUser = (selected) => {
    const params = {
      queueIds: JSON.stringify(jsonString),
      users: JSON.stringify(selected),
      status: JSON.stringify(status.map(item => item.id))
    };

    fetchTickets(params, tags);
    setSelectedUsers(selected);
  }

  const handleChangeStatus = (selected) => {
    const params = {
      queueIds: JSON.stringify(jsonString),
      users: JSON.stringify(users.map(item => item.id)),
      status: JSON.stringify(selected)
    };

    fetchTickets(params, tags);
    setSelectedStatus(selected);
  }

  return (
    <div className={classes.root}>
      <form style={{display: 'flex', gap: '1em'}}>
        {isAdmin && <FormControl margin="dense" variant="outlined" style={{minWidth: '10vw'}}>
          <InputLabel>{i18n.t("kanban.user")}</InputLabel>
          <Select
              id="type-select"
              labelWidth={60}
              name="user"
              onChange={(event) => handleChangeUser(event.target.value)}
              value={selectedUsers}
              multiple={true}
          >
              {users.map(user => 
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
              </MenuItem>)}
          </Select>
        </FormControl>}
        <FormControl margin="dense" variant="outlined" style={{minWidth: '10vw'}}>
          <InputLabel>{i18n.t("kanban.status")}</InputLabel>
          <Select
              id="type-select"
              labelWidth={60}
              name="status"
              onChange={(event) => handleChangeStatus(event.target.value)}
              value={selectedStatus}
              multiple={true}
          >
            {status.map(item => 
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
              </MenuItem>)}
          </Select>
        </FormControl>
      </form>
      <Board 
        data={file} 
        onCardMoveAcrossLanes={handleCardMove}
        style={{backgroundColor: 'rgba(252, 252, 252, 0.03)'}}
      />
    </div>
  );
};


export default Kanban;
