const useCSV = () => {
    const convertJsonToCsv = (data) => {
        if (!data.length) {
          return;
        }
      
        const headers = Object.keys(data[0]);
      
        const rows = data.map(row => headers.map(header => `"${String(row[header]).replace(/"/g, '""')}"` ).join(';'));
      
        return ['\uFEFF'.concat(headers.join(';')), ...rows].join('\n');
      }

    const downloadCSV = (data) => {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(new Blob([convertJsonToCsv(data)], { type: 'text/csv;charset=utf-8;' }))

        link.download = `report_${new Date().getTime()}.csv`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    return { downloadCSV, convertJsonToCsv }
}

export default useCSV;