import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueOptionSelect = ({ queueId, selectedQueueOptionIds, onChange, multiple = true, title = i18n.t("queueOptionSelect.inputLabel"), noColor = true }) => {
	const classes = useStyles();
	const [queueOptions, setQueueOptions] = useState([]);

	useEffect(() => {
		fetchQueueOptions();
	}, [queueId]);

	const fetchQueueOptions = async () => {
		try {
			const { data } = await api.get("/queue-options", { params: { queueId } });
			setQueueOptions(data);
		} catch (err) {
			toastError(err);
		}
	}

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel shrink={selectedQueueOptionIds ? true : false} >{title}</InputLabel>
				<Select
					label={title}
					multiple={multiple}
					labelWidth={60}
					value={selectedQueueOptionIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={selected => {
						return (
							<div className={classes.chips}>
								{selected?.toString().length > 0 && multiple && !noColor ? (
									selected.map(id => {
										const queue = queueOptions.find(q => q.id === id);
										return queue ? (
											<Chip
												key={id}
												style={{ backgroundColor: queue.color }}
												variant="outlined"
												label={queue.title}
												className={classes.chip}
											/>
										) : null;
									})

								) : selected?.toString().length > 0 && !multiple && !noColor ?
									(
										<Chip
											key={selected}
											variant="outlined"
											style={{ backgroundColor: queueOptions.find(q => q.id === selected)?.color }}
											label={queueOptions.find(q => q.id === selected)?.title}
											className={classes.chip}
										/>
									) : (
										<>
										{ queueOptions.find(q => q.id === selected)?.title }
										</>
									)
								}

							</div>
						)
					}}
				>
					{!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
					{queueOptions.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>
							{queue.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueOptionSelect;
