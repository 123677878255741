import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import QueueOptionSelect from "../QueueOptionSelect";
import { RouletteOptions } from "../RouletteOptions";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const RouletteSchema = Yup.object().shape({
	// lastSequence: Yup.number().required("Obrigatório"),
	// queueId: Yup.number().required("Obrigatório")
});

const RouletteModal = ({ open, onClose, setChangeModal, rouletteId }) => {
	const initialState = {
		lastSequence: 1,
		queueId: null
	};

	const classes = useStyles();
	const [roulette, setRoulette] = useState(initialState);
	const [rouletteUser, setRouletteUser] = useState();
	const [users, setUsers] = useState([]);
  const [selectedQueueId, setSelectedQueueId] = useState(null);
  const [selectedQueueOptionId, setSelectedQueueOptionId] = useState(null);
	const [savedOptions, setSavedOptions] = useState(false)

	useEffect(() => {
		setChangeModal(false)
		fetchRoulette();
	}, [rouletteId, open]);

	const fetchRoulette = async () => {
		if (!rouletteId) return;
		try {
			const { data } = await api.get(`/roulette/${rouletteId}`);
			setRoulette(prevState => {
				return { ...prevState, ...data };
			});

			setSelectedQueueId(data.queueId);
			setSelectedQueueOptionId(data.queueOptionId);
			
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		setUsers([...users, rouletteUser])
		
	}, [rouletteUser])

	const removeFirstItem = (items) => {
    if (items.length > 0) {
      const newItems = items.slice(1);
			return newItems
    }
  };

	const handleClose = () => {
		onClose();
		setRoulette(initialState);
		setSavedOptions(false)
	};

	const handleSaveRoulette = async values => {
		let usersFormatted = []
		if (!rouletteId) usersFormatted = removeFirstItem(users)
		const rouletteData = { ...values };
		rouletteData.queueId = selectedQueueId;
    rouletteData.queueOptionId = selectedQueueOptionId;
		rouletteData.users = usersFormatted;
		try {
			if (rouletteId) {
				await api.put(`/roulette/${rouletteId}`, rouletteData);
			} else {
				await api.post("/roulette", rouletteData);
			}
			setRouletteUser([])
			setUsers([])
			setChangeModal(true)
			toast.success(i18n.t("rouletteModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{rouletteId
						? `${i18n.t("rouletteModal.title.edit")}`
						: `${i18n.t("rouletteModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={roulette}
					enableReinitialize={true}
					validationSchema={RouletteSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveRoulette(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											type="number"
											label={i18n.t("rouletteModal.form.lastSequence")}
											autoFocus
											error={touched.lastSequence && Boolean(errors.lastSequence)}
											helperText={touched.lastSequence && errors.lastSequence}
											name="lastSequence"
											variant="outlined"
											margin="dense"
										/>
										<Grid sm={6}>
                    <QueueSelect
                      selectedQueueIds={selectedQueueId}
                      hasRoulette={true}
                      noColor={true}
                      name={'queueId'}
                      onChange={(selectedId) => {
                        setSelectedQueueId(selectedId)
                      }}
						          fullWidth
                      multiple={false}
                      title={'Fila'}
                    />

                    {selectedQueueId && <QueueOptionSelect
                      queueId={selectedQueueId}
                      selectedQueueOptionIds={selectedQueueOptionId}
                      noColor={true}
                      name={'queueOptionId'}
                      onChange={(selectedId) => {
                        setSelectedQueueOptionId(selectedId);
                      }}
                      fullWidth
                      multiple={false}
                      title={'Subfila'}
                    />}
										</Grid>
								</div>
							
								<RouletteOptions 
									rouletteId={rouletteId}
									setRouletteUser={setRouletteUser} 
									setSavedOptions={setSavedOptions}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("rouletteModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting || !savedOptions}
									variant="contained"
									className={classes.btnWrapper}
								>
									{rouletteId
										? `${i18n.t("rouletteModal.buttons.okEdit")}`
										: `${i18n.t("rouletteModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default RouletteModal;
